.inicioContainer {
  width: 100%;

  .industriaContainer,
  .introduccionElementos,
  .nosotros__container,
  // .contacto__container,
  .carousel_tacome {
    max-width: 1200px;
    margin: auto;
  }

  .video__main {
    // display: none;
    box-sizing: border-box;
    margin: auto;
    // background-color: black;
    video {
      max-height: 85vh;
      width: 100%;
    }
  }
  .industriaContainer {
    height: 900px;
    img {
      width: 100%;
      height: 200px;
      background: gray;
    }
  }
  .introduccionContainer {
    $thisHeight: 600px;
    display: grid;
  }
}

.carousel.carousel_tacome,
.carousel_tacome {
  & .control-arrow {
    height: 25%;
    margin: auto 25px;
    background-color: transparent ;
  }
  & .slide.selected {
    .element .contentText {
      animation: h1InProducts 1s ease-in-out;
      &::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
  & .element {
    height: 80vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60% 20% 20%;
    justify-items: center;

    transition: 300ms transform ease-in-out;

    .contentText {
      position: relative;
      align-self: center;
      display: flex;
      padding: 15px 0;

      a {
        font-size: 68px;
        font-weight: 900;
        letter-spacing: .5px;
        color: lighten($color: $azulTacome, $amount: 3);
      }

      box-sizing: border-box;
      width: auto;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 10px;
        border-bottom: 2px solid rgba($azulTacome, .5);
        transition: 250ms ease-in-out;
        transition-delay: 750ms;
        clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%);
      }
    }
    img {
      width: auto !important;
      height: 100% !important;
      align-self: center !important;
    }
  }
}
@media screen and (max-width: 720px) {
  .carousel_tacome {
    & .element {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}

.introduccionElementos {
  display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  // justify-items: center;
  overflow: auto;
  width: 100%;

  .element__container {
    position: relative;
    
    filter: grayscale(100%);
    background-size: cover;
    background-position: 50%;

    width: 25%;
    height: 450px;
    // margin: 0 3px;

    article {
      position: absolute;
      width: 100%;
      height: 100%;

      transition: 250ms ease-in-out;
      color: white;
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba($azulTacome, .5) 75%, rgba($azulTacome, 1) 100%);
    }

    transition: 250ms ease-in-out;
    &:hover {
      filter: grayscale(0%);
      width: 30%;

      & .fa-tools {
        transition: 0.9s ease-in-out;
        transform: rotate(360deg);
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .introduccionElementos {
    justify-items: unset;

    .element__container {
      width: unset;
      article {
        width: 250px;
      }

      &:hover {
        width: unset;
        article {
          width: 300px;
        }
      }
    }
  }
  
}


.content {
    transition: all 0.3s ease-in-out;
}

.itmaContainer {
    padding: 100px 0;
    display: grid;
    .date {
      margin: auto;
    }
    picture {
      width: 100%;
      max-width: $contentMaxWidth;
      display: block;
      margin-left: auto;
      margin-right: auto;
      .itma_stand_small {
        display: none;
      }
      #mapa {
        #stand {
          #tapa,
          #globo,
          #mapa {
            transition: all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
          #globo {
            opacity: 0.0001;
            -webkit-transform: scale(0.7) translate(250px, 150px);
            transform: scale(0.7) translate(250px, 150px);
          }
          #logo {
            transition: all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
            .animated {
              animation: bounce 1000ms infinite;
            }
          }
        }
      }
    }
    &.inView {
      picture {
        #mapa {
          -webkit-transform: translate(0, 100px) 500ms
            cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transform: translate(0, 100px) 500ms
            cubic-bezier(0.785, 0.135, 0.15, 0.86);
          #stand {
            #tapa {
              -webkit-transform: translate(0, -100px);
              transform: translate(0, -100px);
            }
            #logo {
              -webkit-transform: translate(0, -100px);
              transform: translate(0, -100px);
            }
  
            #globo {
              opacity: 1;
              transition-delay: 200ms;
              -webkit-transform: scale(1) translate(25px, 25px);
              transform: scale(1) translate(25px, 25px);
            }
          }
        }
      }
    }
}
@media screen and (max-width: 780px) {
  .itmaContainer {
    padding: 50px 5px;
    picture {
      .itma_stand {
        display: none;
      }
      .itma_stand_small {
        display: block;
      }
      #globo {
        -webkit-transform: scale(0.8) translate(75px, 125px);
        transform: scale(0.8) translate(75px, 125px);
      }
      #mapa:hover #stand {
        #globo {
          -webkit-transform: scale(1) translate(0px, 0px);
          transform: scale(1) translate(0px, 0px);
        }
        #tapa,
        #logo {
          -webkit-transform: translate(0, -125px);
          transform: translate(0, -125px);
        }
      }
    }
  }
}


.video__main {
  background-color: rgba(0,0,0,0);;

  video {
    width: 100%;
  }
}