@import "./colors.scss";
.carousel {
  .block {
    display: grid;
    justify-content: center;
  }
  button {
    height: 33%;
    top: 33%;
    margin: auto 5px;
    background: rgba(white, 0.5);
    color: black;
    box-shadow: 1px 1px 5px black;
  }
  .control-arrow {
    margin-top: auto !important;
    margin: auto;
    background: $azulTacome !important;
  }
  .next,
  .control-next {
    position: absolute;
    right: 0;
  }
  .prev,
  .control-prev {
    position: absolute;
    left: 0;
  }
  .react-swipe-container {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .slide {
    background-color: white !important;
  }
}
