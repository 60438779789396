/*
*   #HEADER    
*/

@media screen and (min-width: 780px) {
  header {
    display: grid;
    position: fixed;
    align-content: center;
    background: white;
    z-index: 5;
    border-bottom: .5px solid rgba($azulTacome, 0.4);
    width: 100%;

    .buttonNav {
      display: none;
    }
    .nav_holder {
      display: grid;
      background-color: rgba(255, 255, 255, 0);
      height: $headerSize;
      transition: 0.25s ease-in-out;
  
      &.fixed {
        height: calc(#{$headerSize} - 25px);
  
        .svgTacomeName {
          height: calc(#{$headerSize} - 50px);
        }
      }
  
      & .svgTacomeName {
        display: flex;
        margin-right: 100px;
        transition: height 0.25s ease-in-out;
        margin: 5px auto;
        height: 44px;
        // transition: 1s ease-in-out;
      }
      & .links {
        @include aparecer;
        display: grid;
        grid-template-columns: 3fr repeat(4, 1fr);
        margin: auto;
        width: 100%;
        max-width: 1400px;
        height: 100%;
  
        .link {
          @include gridCenter;
          letter-spacing: 0.3rem;
          animation-duration: 0.8s;
          animation-name: aparecer;
          font-size: 1.2rem;
  
          > a {
            @include aSubline;
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: 0;
              left: 0;
              background-color: $azulTacome;
              visibility: hidden;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transition: all 0.2s ease-in-out 0s;
              transition: all 0.2s ease-in-out 0s;
            }
          }
          .productosList {
            z-index: 1;
            position: absolute;
            top: $headerSize;

            width: 300px;
            box-sizing: border-box;
            background-color: white;
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            color: $azulTacome;
            display: flex;
            flex-direction: column;
  
            transition: all 250ms ease-in-out;

            .product {
              padding: 15px 10px;
              background-color: rgba($azulTacome, 1);
              color: white;
              font-weight: 100;
              &:hover {
                background-color: rgba($azulTacome, 0.9);
              }
            }

            &.open {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
          }
        }
      }
  
      @media screen and (max-width: 1150px) {
        padding: 0 0.5vw;
      }
    }
  }
}
//
@media screen and (max-width: 780px) {
  header {
    width: 100%;
    height: 0;
    position: fixed;
    z-index: 99;

    .buttonNav {
      display: block;
      position: fixed;
      z-index: 2;
      top: 0;
      left: 3px;
      cursor: pointer;
      margin: 10px 5px;
      @include easOut(0.2s);
      & .line {
        height: 4px;
        width: 35px;
        background: $verdeOrg;
        // background: lighten($color: $azulTacome, $amount: 10%);
        margin-top: 5px;
        @include easOut(0.2s);
      }
      &.fixed {
        animation: pop 0.3s ease;
        & .line:nth-child(1) {
          -webkit-transform: rotate(45deg) translate(10px, 10px);
          transform: rotate(45deg) translate(10px, 10px);
        }
        & .line:nth-child(2) {
          opacity: 0;
        }
        & .line:nth-child(3) {
          -webkit-transform: rotate(-45deg) translate(2px, -3px);
          transform: rotate(-45deg) translate(2px, -3px);
        }
      }
    }
    .nav_holder {
      box-sizing: border-box;
      overflow: auto;
      max-height: 100vh;
      padding: 45px 5px 45px 35px;
      background: white;
      border-bottom: 1px solid rgba($azulTacome, 0.5);

      transition: 250ms ease-in-out;
      transform: translate(0, -100%);
      opacity: 0;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

      & .svgTacomeName {
        height: 25px;
      }

      & .links {
        display: flex;
        flex-direction: column;

        .link {
          position: relative;
          margin: 5px 0;
          font-size: 32px;

          &:first-child{
            align-self: center;
          }
          &:not(:first-child) {
            transition: left 250ms ease-in-out, 350ms ease-in-out;
            transition-delay: 250ms;
            left: -10px;
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
            opacity: 0;
          }
        }

      }

      &.fixed {
        opacity: 1;
        transform: translate(0, 0);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        .link {
          &:not(:first-child) {
            transition-delay: 0;
            left: 0px;
            opacity: 1;
            clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
          }
        }
      }
    }
  }
}
