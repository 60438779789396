/*
*   #FOOTER    
*/
footer {
  grid-area: footer;

  & .language__container {
    height: $footerSize;
    background-color: lighten($color: $azulTacome, $amount: 5);
  
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  & .select__object {
    position: relative;

    span {
      padding: 3px 7px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 10px;
      align-items: center;
      cursor: pointer;
    }
    .options__container {
      transition: all 250ms ease-in-out;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
      position: absolute;
      display: flex;
      flex-direction: column-reverse;
      top: -250%;
      background-color: white;
      border: 1px solid rgba($azulTacome, .5);
      color: $azulTacome;

      & .option__container {
        display: grid;
        gap: 3px;
        grid-template-columns: auto 1fr;
        padding: 3px 7px;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: rgba($azulTacome, .1);
        }
      }
    }
    &.visible {
      .options__container {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
}

.generalitat-ayuda {

  max-width: 900px;
  margin: auto;

  padding: 25px 15px;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  
  & .image img {
    width: 340px;
  }
  & .text {
    text-align: left;
    width: 100%;
    font-size: 12px;
  }

  @media screen  and (max-width: 820px){
    & .image img {
      width: 140px;
    }
    & .text {
      font-size: 10px;
    }
  }
}