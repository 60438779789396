/*
*   #COLORS
*/
.white {
  color: white;
}
.verdeOrg {
  &.color {
    color: #849146 !important;
  }
  &.background {
    background-color: #849146 !important;
  }
}
.azulTacome {
  &.color {
    color: #004f98 !important;
  }
  &.background {
    background-color: #004f98 !important;
  }
}

$white: white;
$verdeOrg: #849146;
$azulTacome: #004f98;
