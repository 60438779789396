// Mixin animaciones
@mixin easOut($duration: 0.8s, $target: all, $type: ease-out) {
  transition: $target $duration $type;
}
@mixin aparecer {
  animation-name: aparecer;
  animation-duration: 1s;
}
@mixin desaparecer {
  animation-name: desaparecer;
  animation-duration: 1s;
}

// ANIMATION
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes right_to_left {
  from {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
*.animacion {
  @include easOut();
  @for $x from 1 through 5 {
    &.aparecer-#{$x} {
      animation-duration: 0.8s * $x;
      animation-name: aparecer;
    }
  }
  &.left_to_right {
    animation-name: left_to_right;
    animation-duration: 1s;
  }
  &.right_to_left {
    animation-name: right_to_left;
    animation-duration: 1s;
  }
}
@keyframes longer {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes aparecer {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes desaparecer {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes bigToSmall {
  0% {
    color: #004f98;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
      1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    font-size: 7rem;
  }
  60% {
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
      1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    font-size: 7rem;
  }
  79% {
    color: #004f98;
  }
  to {
    text-shadow: 2px 0 0 $azulTacome, -2px 0 0 $azulTacome, 0 2px 0 $azulTacome,
      0 -2px 0 $azulTacome, 1px 1px $azulTacome, -1px -1px 0 $azulTacome,
      1px -1px 0 $azulTacome, -1px 1px 0 $azulTacome;
    font-size: 4rem;
    color: white;
  }
}
@keyframes productosAnimacion {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes productosAnimacionOut {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
}
// Products
@keyframes h1InProducts {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
}
@keyframes goTo {
  0% {
    stroke-dashoffset: 5000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes boxesProductos {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes pop {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  75% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes moveLikeJagger {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  15% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  25% {
    -webkit-transform: translate(5px, 10px);
    transform: translate(5px, 10px);
  }
  55% {
    -webkit-transform: translate(8px, 7px);
    transform: translate(8px, 7px);
  }
  65% {
    -webkit-transform: translate(3px, 2px);
    transform: translate(3px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, 5px);
    transform: translate(-2px, 5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes buttonClose {
  from {
    opacity: 0;
  }
  75% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounceSpecialTop {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, -200px, 0);
    transform: translate3d(0, -200px, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -230px, 0);
    transform: translate3d(0, -230px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -215px, 0);
    transform: translate3d(0, -215px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -204px, 0);
    transform: translate3d(0, -204px, 0);
  }
}

@keyframes tapMove {
  to {
    -webkit-transform: translate(0, -200px);
    transform: translate(0, -200px);
  }
}
