@import "carousel.scss";
@import "./colors.scss";
@import "./animaciones.scss";
@import "./propiedades.scss";
@import "./mixins.scss";

@import "./footer";
@import "./header";

@import "../components/Inicio/Inicio";
@import "../components/Contacto/contactView";
@import "../components/Productos/Productos";
@import "../components/Resources/index";
@import "../components/Nosotros/Nosotros";

html,
body {
  font-family: "Muli", "Open Sans Condensed";
}

body {
  margin: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    // background-color: darkgrey;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba($azulTacome, 0.3);
  }
}

html {
  scroll-behavior: smooth;
}

.app {
  font-family: "Open Sans Condensed";
  margin: 0;
  transition-duration: 1s;

  @media screen and (max-width: 780px) {
    header {
      height: 0;
      & .nav_holder {
        -webkit-transform: translate(0, -1900%);
        transform: translate(0, -1900%);
        opacity: 0;
      }
    }
  }
  @media screen and (min-width: 780px) {
    header .buttonNav {
      -webkit-transform: translate(0, -900%);
      transform: translate(0, -900%);
    }
  }
}


#main {
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: $headerSize;
    transition: height 0.2s ease;
  }
}
main#main {
  grid-area: content;
  min-height: calc(100vh - #{$footerSize});
}
