@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,700,900");
// PROPIEDADES

$headerSize: 75px;
$footerSize: 50px;
$contentMaxWidth: 1400px;
$contentSize: calc(100vh - #{$headerSize} - #{$footerSize});

// Cursor
h1,
h2,
h3,
h4,
p {
  cursor: default;
}

h1 {
  letter-spacing: 0.2rem;
  font-weight: bold;
  font-family: "Muli", "Open Sans" !important;
}
h2 {
  letter-spacing: 0.1rem;
}
h3 {
  letter-spacing: 0.05rem;
}
h4 {
  letter-spacing: 0.05rem;
  font-weight: 400;
}
.titulo {
  color: $azulTacome;
  text-shadow: 2px 2px 15px white;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  &.smallBorder {
    text-shadow: 1px 1px 10px white;
  }
}
.subtitulo {
  color: $verdeOrg;
  letter-spacing: 0.01rem;
}
.slogan {
  color: white;
  font-weight: bold;
  font-style: italic !important;
}
.tituloOrg {
  font-family: "Open Sans" !important;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-shadow: 2px 0 0 $azulTacome, -2px 0 0 $azulTacome, 0 2px 0 $azulTacome,
    0 -2px 0 $azulTacome, 1px 1px $azulTacome, -1px -1px 0 $azulTacome,
    1px -1px 0 $azulTacome, -1px 1px 0 $azulTacome;
}
button {
  border: none;
  outline: none;
  border-radius: 15px;
  background: $azulTacome;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  transition: 0.2s ease-in-out;
  z-index: 1;
  margin: 0 15px;
  color: white;
  cursor: pointer;
  & .link {
    letter-spacing: 0.2rem;
  }
  &:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    color: white;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.75);
  }
}
// OPCIONES

.blockScroll {
  overflow-y: hidden;
}
.fit {
  width: fit-content;
  width: -moz-fit-content;
}
.bold {
  font-weight: bold;
}

a {
  color: $azulTacome;
  position: relative;
  text-decoration: none;
  letter-spacing: -0.01em;
  &.active {
    color: $verdeOrg;
  }
  &:hover {
    color: $verdeOrg;
  }
}

.font-bold {
  font-weight: bold;
}

* .noBorder {
  border: none;
  text-shadow: none !important;
}

* .cursiva {
  font-style: italic !important;
  font-weight: 400;
}

.textC {
  text-align: center;
}

.noResults {
  min-height: 80vh;
  text-align: center;
  transition: all 1s ease-in-out;
  font-weight: 100;
  animation: aparecer 0.2s ease-in-out;
}
.flex {
  display: flex;
  &.wrap {
    flex-wrap: wrap;
  }
  &.justCen {
    justify-content: center;
  }
  &.column {
    flex-direction: column;
  }
  &.alignCen {
    align-items: center;
  }
}
.grid {
  display: grid;
  &.gridCenter {
    justify-content: center;
    align-content: center;
  }
}

@for $x from 1 through 5 {
  * .filter-#{$x} {
    background: rgba($azulTacome, 0.15 * $x);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
@for $x from 1 through 100 {
  * .pad-#{$x} {
    padding: #{$x}px;
  }
}
@for $x from 1 through 100 {
  * .width-#{$x} {
    width: #{$x}#{"%"};
  }
}
@for $x from 1 through 100 {
  * .marg-#{$x} {
    margin: #{$x}px !important;
  }
  * .margL-#{$x} {
    margin-left: #{$x}px;
  }
  * .margR-#{$x} {
    margin-left: #{$x}px;
  }
  * .margT-#{$x} {
    margin-left: #{$x}px;
  }
  * .margB-#{$x} {
    margin-left: #{$x}px;
  }
}
@for $x from 1 through 10 {
  * .letSpa-#{$x} {
    letter-spacing: #{$x / 10}rem;
  }
}

@for $x from 1 through 5 {
  * .rem-#{$x} {
    font-size: #{$x}rem;
  }
}

@for $x from 1 through 9 {
  * .weight-#{$x} {
    font-weight: #{$x}00 !important;
  }
}

svg {
  height: 100%;
}

.svg {
  &.background svg {
    height: 900px;
    position: absolute;
    z-index: -1;
    width: 100%;
    left: 0;
  }
}

.Input {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin: 0 15px;
  padding: 5px;
  i {
    font-size: 1.2rem;
    opacity: 1 !important;
    margin-left: 7px !important;
  }
}

datalist {
  option {
    color: red;
  }
}

// CORREGIR OTRAS LIBRERIAS

h1:first-child() {
  margin: initial;
}

// Functions

@function get-vw($target) {
  $vw-context: (1000 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

// Fixs

.slider-control-centerright {
  right: 1vw !important;
}
.sliderButton {
  border: 0px;
  border-radius: 10px;
  background: rgba($azulTacome, 0.6);
  padding: 15px;
  opacity: 1;
  cursor: pointer;
}

.align-self {
  &.center {
    align-self: center;
  }
}
.backgroundImage {
  position: absolute;
  width: 100%;
  z-index: -1;
  background-size: cover;
}

.border {
  &.circle {
    overflow: hidden;
    border-radius: 50%;
  }
}

.lineTop {
  &::before {
    content: "";
    display: block;
    margin: 0 auto 30px auto;
    width: 65%;
    height: 3px;
    transition: 1s width ease-in-out;
    background: white;
  }
}
.nomargin {
  margin: 0;
}
.marginauto {
  margin: auto;
}
.input-tc {
  margin: 5px;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  outline: none;
  height: 44px;
  padding: 0 17px 0 15px;
}
.Language {
  margin-left: 15px;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonLink2 {
  border: none;
  outline: none;
  border-radius: 15px;
  background: $azulTacome;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  margin: 0 15px;
  color: white;
  padding: 15px 25px;
  cursor: pointer;
  a {
    color: white;
  }
}
