// MIXIN

@mixin gridCenter($items : false) {
	display: grid;
	align-content: center;
	justify-content: center;
	@if $items == false {
		justify-items: center;
	}
}
@mixin aSubline {
	&:hover {
		transition: all 0.2s ease-out;
		// font-size: 1.3rem;
		color: $verdeOrg;
	}
	&:hover::before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
	&.active::before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}
@mixin filterBackgroundImg($background) {
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: $background;
	}
}
@mixin gradiantAzult-1 {
	background: rgba(9, 100, 179, 1);
	background: -moz-linear-gradient(left, rgba(9, 100, 179, 1) 0%, rgba(3, 119, 214, 1) 100%);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(9, 100, 179, 1)),
		color-stop(100%, rgba(3, 119, 214, 1))
	);
	background: -webkit-linear-gradient(left, rgba(9, 100, 179, 1) 0%, rgba(3, 119, 214, 1) 100%);
	background: -o-linear-gradient(left, rgba(9, 100, 179, 1) 0%, rgba(3, 119, 214, 1) 100%);
	background: -ms-linear-gradient(left, rgba(9, 100, 179, 1) 0%, rgba(3, 119, 214, 1) 100%);
	background: linear-gradient(to right, rgba(9, 100, 179, 1) 0%, rgba(3, 119, 214, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0964b3', endColorstr='#0377d6', GradientType=1);
}
@mixin gradiantAzul-2 {
	background: rgba(173, 174, 255, 0.18);
	background: -moz-linear-gradient(
		left,
		rgba(173, 174, 255, 0.18) 0%,
		rgba(83, 86, 252, 0.18) 52%,
		rgba(0, 4, 249, 1) 100%
	);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba(173, 174, 255, 0.18)),
		color-stop(52%, rgba(83, 86, 252, 0.18)),
		color-stop(100%, rgba(0, 4, 249, 1))
	);
	background: -webkit-linear-gradient(
		left,
		rgba(173, 174, 255, 0.18) 0%,
		rgba(83, 86, 252, 0.18) 52%,
		rgba(0, 4, 249, 1) 100%
	);
	background: -o-linear-gradient(
		left,
		rgba(173, 174, 255, 0.18) 0%,
		rgba(83, 86, 252, 0.18) 52%,
		rgba(0, 4, 249, 1) 100%
	);
	background: -ms-linear-gradient(
		left,
		rgba(173, 174, 255, 0.18) 0%,
		rgba(83, 86, 252, 0.18) 52%,
		rgba(0, 4, 249, 1) 100%
	);
	background: linear-gradient(
		to right,
		rgba(173, 174, 255, 0.18) 0%,
		rgba(83, 86, 252, 0.18) 52%,
		rgba(0, 4, 249, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#adaeff', endColorstr='#0004f9', GradientType=1);
}
