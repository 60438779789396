@import "./Rame/Rame";
@import "./Empaquetadora/Empaquetadora";
@import "./EstampaciónDigital/EstampacionDigital";
@import "./Percha/Percha";
@import "./Preparaciones/Preparaciones";
@import "./Rame/Rame";
@import "./Secaderos/Secadero";
@import "./TrenDeLavado/TrenDeLavado";
@import "./Tundosa/Tundosa";
@import "./Vaporizador/Vaporizador";

.producto__main {
    // max-width: 1200px;
    // margin: auto;
    padding: 0 20px;
    font-family: "Poppins";

    margin-bottom: 50px;

    .contactUs__container,
    .modelo__container,
    .descripcion__container,
    .carousel__container,
    .fichaTecnica__container {
      max-width: 1200px;
      margin: auto;
    }

    .informacion__container {
      position: relative;
      min-height: 80vh;

      & .text {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        text-align: center;

        position: relative;
        padding: 25px;
        z-index: 1;
        width: fit-content;
        

        h1 {
          text-overflow: clip;
          font-size: 3.5em;
          font-weight: 900;
          letter-spacing: .5px;
          color: $azulTacome;
          animation: h1InProducts 350ms ease-in-out;
        }
        p {
          animation: h1InProducts 450ms ease-in-out;
        }
        p b {
          background-color: $azulTacome;
          padding: 5px;
          color: white;
          width: fit-content;
        }
      }
      & .image {
          position: absolute;
          left: 0;
          // left: 50%;
          top: 0;
          height: 100%;
          width: 100%;
          // width: 50%;

          .loading__container {
            font-size: 44px;
            position: absolute;
            font-weight: 100;
            text-align: center;
            left: 50%;
            top: 50%;
            z-index: 1;
          }
          canvas {
            z-index: 0;
            image-rendering: pixelated;
          }
      }
    }
    .contactUs__container {
      @keyframes textIn {
        from {
          opacity: 0;
          transform: translate(0, 10px);
          // clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        }
        to {
          opacity: 1;
          transform: translate(0, 0);
          // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
      background: rgba($azulTacome, 1);
      display: grid;
      justify-content: center;
      color: white;
      font-size: 22px;
      padding: 5px 0;

      > * {
        text-align: center;
        transition: all 150ms ease-in-out;
        animation: textIn 1s ease-in-out;
        cursor: pointer;
      }
      &:hover {
        > * {
          transform: scale(1.1);
        }
      }
    }
    .modelo__container {
        height: 300px;
        width: 100%;
        display: flex;        
        justify-content: center;
        align-items: center;
    }
    .descripcion__container {
        background: rgba(0, 0, 0, 0.01);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 77px 0;

        p {
          font-size: 18px;
          font-weight: 100;
          letter-spacing: .8px;
          color: rgb(120, 120, 120);
          padding: 0 25px;
        }
    }
    .carousel__container {
        & .element {
            height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: auto;
              height: 100%;
            }
        }
        & .control-arrow {
          margin: auto 15px;
          box-shadow: none;
        }
    }
    .fichaTecnica__container {
      background: rgba(0, 0, 0, 0.01);

      h2 {
        position: relative;
        font-size: 1.7em;
        letter-spacing: 2px;
        font-weight: 500;

        width: 100%;
        &::before {
          content: "";
          width: 100%;
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid rgba($azulTacome, 0.2);
        }
      }

      table {
        // border: 1px solid rgba($azulTacome, 0.2);
        width: 100%;
        border-collapse: collapse;

        tr {

          td {
            padding: 5px 10px;
            border: 1px solid rgba($azulTacome, 0.2);
            &:first-child {
              background-color: rgba($azulTacome, 1);
              border-color: white;
              color: white;
              font-size: 1.2em;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
}
@media screen and (max-width: 720px) {
  .producto__main {
    padding: 0 5px;
    .informacion__container {
      min-height: 50vh;
      border: 1px solid ;

      & .text {
        padding: 5px;
        text-align: center;
      }
      & .image {
        height: 500px;
        position: relative;
        .loading__container {
          left: 0;
          right: 0;
          margin: auto;
        }

      }
    }

    .carousel__container {
      & .element {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}


.productos__container {
  max-width: 1200px;
  margin: auto;

  & .links__container {
    display: grid;    
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);
    
    & .link {
      position: relative;
      height: 300px;

      display: flex;      
      justify-content: center;
      align-items: center;
      
      border: 1px solid rgba($azulTacome, 0.2);
      box-sizing: border-box;

      & .background {
        position: absolute;
        height: auto;
        width: 100%;
        background-position: 50%;
        background-size: cover;
        transition: 250ms ease-in-out;
        filter: grayscale(100%);
      }
      a {
        text-align: center;
        padding: 25px;
        color: black;
        font-weight: 400;
        font-size: 28px;
        letter-spacing: 1px;
        background-color: rgba(white, 0.8);
        transition: 250ms ease-in-out;
      }

      &:hover {
        & .background {
          transform: scale(0.95);
          filter: grayscale(50%);
        }
        a {
          color: $azulTacome;
        }
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .productos__container {
    & .links__container {
      display: grid;    
      grid-template-columns: 1fr;
    }
  }  
}