.contacto__container {
  background-color: lighten($color: $azulTacome, $amount: 10);
}
.contacto__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-content: center;

  padding: 25px 0;
  margin: auto;

  width: 100%;
  max-width: $contentMaxWidth;
  box-sizing: border-box;
  
  & .contact_info {
    display: grid;
    grid-template-rows: repeat(auto-fit, (minmax(50px, 1fr)));
    justify-self: center;
    align-self: center;

    color: $azulTacome;
    background: white;
    border-radius: 15px 15px 15% 15px;
    padding: 15px 25px;

    width: 100%;
    max-width: 500px;
    height: 500px;
    box-sizing: border-box;

    section {
      @include gridCenter(true);
      align-items: center;
      grid-template-columns: [icon] auto [text] 1fr;
      font-weight: bold;
      margin: 10px 0;
      i {
        grid-column: icon;
        padding: 10px 10px 10px 0;
        align-self: center;
        justify-self: center;
      }
      .link {
        display: flex;
      }
    }
  }
  .worldMap {
    box-sizing: border-box;
    padding: 15px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 450px;
      width: 100%;
      height: fit-content;
    }
  }
}
@media screen and (max-width: 780px) {
  .contacto__content {
    grid-template-columns: 1fr;
    gap: 5px;

    & .contact_info {
      padding: 10px 15px;
      height: 400px;
      // max-width: unset;
      // width: unset;
    }
  }
}
