.nosotros__container {
  max-width: 1200px;
  margin: auto;
  margin-top: 90px;

  display: grid;

  ._titulo {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $azulTacome;
  }
  ._subtitulo {
    font-size: 26px;
    font-weight: 200;
  }

  .topContainer {
    text-align: center;
    align-self: center;
    padding: 0 15px;
  }
  .trayectoriaContainer {
    align-self: center;
    padding: 0 15px;
    margin-bottom: 90px;

    p {
      font-size: 24px;
      font-weight: 100;
      letter-spacing: .8px;
      color: rgb(120, 120, 120);
    }
  }
  .slogan__container {
    position: relative;
    align-self: center;
    justify-self: center;
    
    background-size: cover;
    background-position: 50%;

    width: 100%;
    height: 300px;

    display: grid;
    justify-content: center;
    align-items: center;

    &::before {
      z-index: 0.1;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
      
    h4 {
      z-index: 1;
      letter-spacing: 1.5px;
      color: white;
      padding: 5px;
      font-weight: 800;
    }
  }
}
  