@keyframes showModal {
  from {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
  to {
    -webkit-transform: translate(0%, 0);
    transform: translate(0%, 0);
  }
}
@keyframes closeModal {
  from {
    -webkit-transform: translate(0%, 0);
    transform: translate(0%, 0);
  }
  to {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}


.modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 5;
    // display: none;
    border: none;
    transition: transform 250ms ease;
    display: flex;
    justify-content: center;
    align-items: normal;

    &::-webkit-scrollbar {
      -webkit-box-shadow: unset;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: unset;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: unset;
      background-color: transparent;
    }

    .buttonClose {
      position: relative;
      top: -50px;
      left: -50px;

      content: "X";

      width: 30px;
      height: 30px;
      background: $azulTacome;
      border-radius: 3px;
      box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      color: white;

      &:hover {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
      }
      &::after {
        ~ {
          display: none;
        }
      }
    }

    .mainContent {
      position: relative;

      max-width: 900px;
      max-height: 85vh;

      border: 3px solid $azulTacome;
      overflow: auto;
      margin: auto;
      flex-direction: column;
      align-items: center;
      background: white;
      border-radius: 15px;
    }
  
    transition: 250ms ease-in-out;
    &.show {
      animation: showModal 250ms ease-in-out;
    }
    &.hidden {
      transform: translate(-100%, 0);
    // animation: closeModal 250ms ease-in-out;
    }
    @media screen and (max-width: 780px) {
      .mainContent {
        width: 95vw;
      }
    }
  }